<template>
  <div>
    <v-data-table
      dense
      :headers="getHeaders"
      :items="getProducts"
      item-key="name"
      class="elevation-1 sale-report-table"
      :hide-default-footer="true"
      :options="getOptions"
    >
      <!-- <template slot="body.prepend">
        <tr class="grey lighten-2">
          <th>Summary</th>
          <th>{{ sumField('view_content') }}</th>
          <th>{{ sumField('add_to_cart') }}</th>
          <th>{{ sumField('reached_checkout') }}</th>
          <th>{{ sumField('session_converted') }}</th>
          <th>{{ sumField('orders') }}</th>
          <th>{{ avgField('conversion_rate') }}%</th>
          <th>{{ sumField('net_qty') }}</th>
          <th>{{ currency.priceFormat(avgField('aov')) }}</th>
          <th>{{ avgField('aoi') }}</th>
          <th>{{ currency.priceFormat(sumField('total_sale')) }}</th>
        </tr>
      </template> -->
      <template v-slot:[`item.title`]="{ item }">
        <div class="pa-2">
          <v-tooltip right color="#000000">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex" v-bind="attrs" v-on="on">
                <img
                  :src="item.images[0] ? imageHelpers.url(item.images[0].src) : '@/assets/images/no-image.png'"
                  srcset=""
                  width="40"
                />
                <div class="d-flex word-break-all ml-2">
                  <div v-if="!item.deleted && item.store == getStoreId">
                    <router-link class="invent" :to="{ name: 'website.products.update', params: { id: item._id } }">
                      <span class="text-ref-title">{{ item.title }}</span>
                    </router-link>
                  </div>
                  <div v-else-if="!item.deleted">
                    <span class="text-ref-title">{{ item.title }}</span>
                  </div>
                  <div v-else>
                    <span class="text-ref-title red--text">{{ item.title }}</span>
                  </div>
                </div>
                <v-icon v-if="!item.deleted" class="icon-append-no-rotate" small @click.stop="gotoProduct(item)"
                  >mdi-open-in-new</v-icon
                >
              </div>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.view_content`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <div v-else>{{ item.view_content }}</div>
      </template>
      <template v-slot:[`item.add_to_cart`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.add_to_cart }}</span>
      </template>
      <template v-slot:[`item.reached_checkout`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.reached_checkout }}</span>
      </template>
      <template v-slot:[`item.session_converted`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.session_converted }}</span>
      </template>

      <template v-slot:[`item.orders`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.orders }}</span>
      </template>

      <template v-slot:[`item.conversion_rate`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.conversion_rate }}%</span>
      </template>

      <template v-slot:[`item.net_qty`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.net_qty }}</span>
      </template>

      <template v-slot:[`item.aov`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ currency.priceFormat(item.aov) }}</span>
      </template>
      <template v-slot:[`item.aoi`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ item.aoi }}</span>
      </template>
      <template v-slot:[`item.total_sale`]="{ item }">
        <v-skeleton-loader type="text" v-if="isLoading"></v-skeleton-loader>
        <span v-else>{{ currency.priceFormat(item.total_sale) }}</span>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="filters.page"
        :length="parseInt(getOptions.totalPage) || 1"
        :total-visible="0"
        @input="onPageChange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { FETCH_PRODUCT_SALES_REPORT } from '@/store/actions.type';
import { mapGetters } from 'vuex';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import moment from 'moment';
import STORAGE_NAME from '@/const/storage';
import currency from '@/helpers/currency';

export default {
  props: {
    stores: Array,
    allStores: Array,
    hasSale: String,
    q: String,
    dateRange: {
      from: Date,
      to: Date,
    },
    timezone: String,
  },
  data: function() {
    return {
      isLoading: true,
      imageHelpers,
      imageFake,
      apllyTimeout: null,
      filters: {
        page: parseInt(this.$route.query?.page) || 1,
        from_time: '',
        to_time: '',
        sales: '',
      },
      currency,
    };
  },
  async created() {
    this.reload();
  },
  methods: {
    getColor(calories) {
      if (calories > 400) return 'red';
      else if (calories > 200) return 'orange';
      else return 'green';
    },
    async reload() {
      let query = this.$route.query;
      // let { startDate, endDate, stores } = this.loadQueryFromCache();
      let fromTime = query?.from_time
        ? query?.from_time
        : this.$props.dateRange && this.$props.dateRange.from
        ? moment(this.$props.dateRange.from).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone || 'America/Los_Angeles')
            .format('YYYY-MM-DD');
      let toTime = query?.to_time
        ? query?.to_time
        : this.$props.dateRange && this.$props.dateRange.to
        ? moment(this.$props.dateRange.to).format('YYYY-MM-DD')
        : moment()
            .tz(this.timezone || 'America/Los_Angeles')
            .format('YYYY-MM-DD');
      let _stores = this.$route.stores ? this.$route.stores : this.$props.stores ? this.$props.stores : '';
      this.isLoading = true;
      let params = {
        size: 20,
        page: this.filters.page,
        sales: this.hasSale,
        stores: Array.isArray(_stores) ? _stores?.join(',') : _stores,
        search: this.q,
        from_time: fromTime,
        to_time: toTime,
        timezone: this.timezone || 'America/Los_Angeles',
        hasSale: this.hasSale,
      };
      this.updateRouter(params);
      await this.$store.dispatch(FETCH_PRODUCT_SALES_REPORT, params);
      this.isLoading = false;
    },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
    gotoProduct(item) {
      if (item.handle) {
        let store = this.allStores.find(store => item.store == store.id);
        console.log(store, item.store);
        let domain = store?.storeDetail?.defaultSystemDomain || this.$store.state.auth.accountInfo.rDomain;
        window.open('https://' + domain + `/products/${item.handle}`);
      }
    },
    sumField(key) {
      let sum = this.getProducts.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
      return (sum % 1 == 0 ? parseInt(sum) : sum.toFixed(2)) || 0;
    },
    avgField(key) {
      let sum = this.getProducts.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
      let avg = sum / this.getProducts?.length;
      return avg ? avg.toFixed(2) : 0;
    },
    onPageChange(page) {
      this.filters.page = page;
      console.log(`this.filters.page: ${this.filters.page}`);
      this.reload();
    },
    updateRouter(query) {
      this.$router.replace({
        path: this.$route.path,
        query: query,
      });
    },
  },
  computed: {
    ...mapGetters(['getProducts', 'getHeaders', 'getOptions']),
    getStoreId() {
      return localStorage.getItem('store-id') || '';
    },
  },
  watch: {
    stores: function(val) {
      this.filters.page = 1;
      this.reload();
    },
    hasSale: function(val) {
      this.filters.page = 1;
      this.reload();
    },
    q: function(val) {
      if (this.apllyTimeout) clearTimeout(this.apllyTimeout);
      this.filters.page = 1;
      this.apllyTimeout = setTimeout(() => this.reload(), 1000);
    },
    dateRange: function(val) {
      this.filters.page = 1;
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-ref-title {
  width: 8rem;
  min-width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.sale-report-table.v-table tr {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: auto;
  padding: 10px;
}
</style>
