<template>
  <div v-if="getTimezoneDone">
    <div class="analytic-container">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <h1 class="analytic-heading">
              Sales reports
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn :loading="isLoadingReport" outlined large color="primary" @click="exportCsv">
              <v-icon left>mdi-file-excel</v-icon>Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <div class="analytic-sale-report d-flex">
              <SelectStorePopover :storeSelectWatcher="onSelectedStores" :stores="stores" class="mr-2" />
              <PickDatePopover
                :handler="pickedDate"
                :timezone="pagePreference.standardTimezone || 'America/Los_Angeles'"
                class="mr-8"
              />
              <!-- <div class="d-flex mr-2" style="align-items: center"><span>Report by</span></div>
              <div class="select-filter">
                <v-select :items="filters" item-value="value" item-text="key" v-model="filter" hide-details></v-select>
              </div> -->
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4">
            <v-text-field
              color="primary"
              placeholder="Search product name"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2">
            <v-select :items="items" item-value="value" item-text="key" v-model="productHasSale"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <SaleReportDataTable
              :hasSale="productHasSale"
              :stores="stores"
              :q="search"
              :dateRange="dateRange"
              :timezone="pagePreference.standardTimezone"
              :allStores="allStores"
            />
          </v-col>
        </v-row>
      </v-container>
      <!-- End search product -->
    </div>
  </div>
  <div v-else class="analytic-loading">
    <Loading />
  </div>
</template>

<script>
import axios from 'axios';
import PickDatePopover from '../analytics/components/PickDatePopover';
import Loading from '@/components/Loading';
import SelectStorePopover from '../analytics/components/SelectStorePopover';
import SaleReportDataTable from './components/SaleReportDataTable';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';
import moment from 'moment';
import analyticRequest from '@/apis/request/analyticRequest';
export default {
  components: {
    SelectStorePopover,
    PickDatePopover,
    Loading,
    SaleReportDataTable,
  },
  data: function() {
    let query = this.$route.query;
    return {
      isLoadingReport: false,
      stores: query?.stores?.split(',') || [],
      getTimezoneDone: true,
      search: query.search || '',
      items: [
        {
          key: 'Product has sale',
          value: 'true',
        },
        {
          key: 'Product no sale',
          value: 'false',
        },
        {
          key: 'All products',
          value: 'all',
        },
      ],
      productHasSale: query?.sales || 'true',
      filters: [
        {
          key: 'Total Sales',
          value: 'SHOP',
        },
        {
          key: 'Product',
          value: 'PRODUCT',
        },
        {
          key: 'Variant option',
          value: 'VARIANT',
        },
        {
          key: 'Sale over time',
          value: 'SHOP_SALES_OVERTIME',
        },
        {
          key: 'Variant SKU',
          value: 'VARIANT_SKU',
        },
        {
          key: 'Product vendor',
          value: 'PRODUCT_VENDOR',
        },
        {
          key: 'Product type',
          value: 'PRODUCT_TYPE',
        },
      ],
      filter: query.filter || 'SHOP',
      dateRange: {
        from: null,
        to: null,
      },
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  created() {},
  methods: {
    onSelectedStores(stores) {
      this.stores = stores;
    },
    pickedDate(dates) {
      this.dateRange = {
        from: dates.startDate,
        to: dates.endDate,
      };
    },
    async exportCsv() {
      try {
        this.isLoadingReport = true;
        let query = this.$route.query;
        const url = 'products/sale-report/export-excel';

        let data = await analyticRequest.get(url, { params: query });
        let text = data.data;
        console.log(text);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'sale-report.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (error) {
        console.log(error);
      }
      this.isLoadingReport = false;
    },
  },
  computed: {
    allStores() {
      return this.$store.getters.stores;
    },
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
  },
  watch: {
    filter: function(val) {},
    productHasSale: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: query?.size,
          page: query?.page,
          stores: query?.stores,
          search: query?.search,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: val,
        },
      });
    },
    search: async function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: query?.stores,
          search: val,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
    stores: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: val?.join(','),
          search: query?.search,
          from_time: query?.from_time,
          to_time: query?.to_time,
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
    dateRange: function(val) {
      let query = this.$route.query;
      this.$router.push({
        query: {
          size: this.get?.size,
          page: query?.page,
          stores: query?.stores,
          search: query?.search,
          from_time: moment(this.dateRange.from).format('YYYY-MM-DD'),
          to_time: moment(this.dateRange.to).format('YYYY-MM-DD'),
          tz: query?.tz,
          sales: query?.sales,
        },
      });
    },
  },
  destroyed() {
    // No remove auto refresh
    localStorage.removeItem(STORAGE_NAME.SALE_REPORT_QUERY);
  },
};
</script>

<style lang="scss" scoped>
.website-page .website-container {
  max-width: unset !important;
}
.analytic-sale-report {
  text-align: center;
}

.select-filter {
  max-width: 200px;
  min-width: 200px;
}
.analytic-container .l-chart-container {
  .text-black {
    color: #000000;
  }
  .text-bold {
    font-weight: 700;
  }
  .text-small {
    font-size: 12px;
  }
  .l-chart-title {
    font-size: 16px;
  }
  .sub-chart-container {
    height: 100%;
    padding: 16px;
  }
  .sub-chart-container {
    // transform: scale(1.01);

    // margin: -8px;
    .on-hover {
      transition: all 0.5s;
    }
  }
}
.v-text-field {
  margin-top: 0px !important;
}
.analytic-loading {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
