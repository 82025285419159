<template>
  <div class="select-store-popover">
    <v-menu offset-y :close-on-click="true" :close-on-content-click="false" light :offset-overflow="true">
      <template v-slot:activator="{ on, attrs }" outlined color="primary">
        <v-btn v-bind="attrs" v-on="on" class="btn-select-store" color="primary" outlined>
          <v-icon :x-small="true" style="margin-right: 10px">{{ storeIcon }}</v-icon>
          {{ txtSelectedStores }}
          <v-icon :x-small="true">{{ mdiArrow }}</v-icon></v-btn
        >
      </template>
      <v-list class="list-store">
        <v-list-item>
          <v-checkbox label="All stores" v-model="isCheckAll" @click="onSelectAll"></v-checkbox>
        </v-list-item>
        <v-list-item v-for="(store, index) in stores" :key="index">
          <v-checkbox
            ref="stores"
            v-if="store"
            :label="store.storeDetail.defaultSystemDomain"
            dense
            :value="store.id"
            @click="onChangeSelect"
            v-model="selectedStores"
            :id="store.id"
          ></v-checkbox>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { FETCH_STORES } from '@/store/actions.type';
import STORAGE_NAME from '@/const/storage';

export default {
  data: function() {
    // let { stores } = this.loadQueryFromCache();
    let query = this.$route.query;
    let stores = query?.stores && query?.stores?.split(',');
    let currentStoreId = localStorage.getItem('store-id');
    let textSelected =
      this.selectedStores &&
      this.selectedStores.length > 0 &&
      this.selectedStores.length == 1 &&
      this.selectedStores[0] != currentStoreId
        ? `${this.selectedStores.length} stores selected`
        : 'Current store';
    return {
      mdiArrow: 'mdi mdi-menu-down mdi-18px',
      storeIcon: 'fas fa-store-alt',
      selectedStores: stores && stores.length ? stores : [currentStoreId],
      isCheckAll: false,
      timeoutApply: null,
      txtSelectedStores: textSelected,
    };
  },
  props: {
    storeSelectWatcher: Function,
  },
  async mounted() {
    await this.$store.dispatch(FETCH_STORES);
  },
  methods: {
    async onChangeSelect() {
      let currentStoreId = localStorage.getItem('store-id');
      if (this.isCheckAll) {
        this.isCheckAll = false;
      }
      if (this.selectedStores.length <= 0) {
        this.selectedStores = [currentStoreId];
      }
      if (this.selectedStores.length == 1 && this.selectedStores.includes(currentStoreId)) {
        this.txtSelectedStores = `Current store`;
      } else {
        this.txtSelectedStores = `${this.selectedStores.length} stores selected`;
      }
      if (this.timeoutApply) clearTimeout(this.applyTimeout);
      this.timeoutApply = setTimeout(() => {
        this.$props.storeSelectWatcher(this.selectedStores);
        clearTimeout(this.timeoutApply);
      }, 2000);
    },
    async onSelectAll() {
      let currentStoreId = localStorage.getItem('store-id');
      this.selectedStores = [];
      if (this.isCheckAll) {
        this.txtSelectedStores = 'All stores';
        this.$refs.stores.forEach((val, index) => {
          this.selectedStores.push(val.id);
        });
      } else {
        this.selectedStores = [currentStoreId];
        this.txtSelectedStores = 'Current store';
      }

      if (this.timeoutApply) clearTimeout(this.applyTimeout);
      this.timeoutApply = setTimeout(() => {
        this.$props.storeSelectWatcher(this.selectedStores);
        clearTimeout(this.timeoutApply);
      }, 1000);
    },
    loadQueryFromCache() {
      let cacheQuery = localStorage.getItem(STORAGE_NAME.REPORT_SEARCH_QUERY);
      return cacheQuery ? JSON.parse(cacheQuery) : {};
    },
  },
  computed: {
    ...mapGetters(['stores']),
    txtSelected() {
      let currentStoreId = localStorage.getItem('store-id');
      return this.selectedStores &&
        this.selectedStores.length > 0 &&
        this.selectedStores.length == 1 &&
        this.selectedStores[0] != currentStoreId
        ? `${this.selectedStores.length} stores selected`
        : 'Current store';
    },
  },
};
</script>
<style scoped>
.select-store-popover {
  border-radius: 8px;
}

.list-store {
  max-height: 340px;
  max-width: 300px;
  overflow-y: auto;
}

.v-input--selection-controls {
  margin: 0px;
}
.v-input__slot {
  margin: -10px !important;
}

.btn-select-store {
  color: #363f44;
}
</style>
